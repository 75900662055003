import { observer } from 'mobx-react';
import React, { forwardRef, MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer } from 'react';

import { ExportDataProviderType } from '@zf/api-types/data-provider';
import { exportSettingsCategoryType, exportStatus } from '@zf/api-types/enums';
import { InvoiceRowType, InvoiceUBLValidationErrorResponseType } from '@zf/api-types/invoice';
import { createStateReducer } from '@zf/hooks/src/stateReducer';
import HorizontalDivider from '@zf/stella-react/src/atoms/Divider/HorizontalDivider';
import { formatDate, formatDateForExport, formatPeriod } from '@zf/utils/src/date';

import { exportArrayToExcel } from '../../../../components/Button/Excel/ExportToExcel';
import { Button, Disabled, NotificationError, RadioInput } from '../../../../design-system/Components';
import { DialogClickRef, ValidationRef } from '../../../../design-system/ComponentSets/Dialog/Dialog';
import { Paragraph, Spinner } from '../../../../design-system/Foundation';
import { CheckBox } from '../../../../design-system/Inputs';
import { notify } from '../../../../events/notification-events';
import { useStore } from '../../../../hooks/useStore';
import css from './export-dialog.module.scss';
import { InfoBanner } from 'design-system/ComponentSets';
import Interweave from 'interweave';
import FlexElements from '@zf/stella-react/src/atoms/Wrappers/FlexElements';
import DownloadService from 'app-context/services/DownloadService';
import { BillingParametersType } from '@zf/api-types/parameters';

type ExportOption = 'excel' | 'invoiceLines' | 'file' | 'ubl';

type State = {
  exportOption: ExportOption;
  markAsExported: boolean;
  fileFormats: any[] | undefined;
  dataProviders: ExportDataProviderType[] | undefined;
  billingParameters: BillingParametersType | undefined;
  invoiceIds: string[];
  validationResponse: InvoiceUBLValidationErrorResponseType | undefined;
};

type Props = {
  selectedRows: InvoiceRowType[];
  validationRef: MutableRefObject<ValidationRef | undefined>;
};

const ExportDialog = forwardRef((props: Props, ref: Ref<DialogClickRef | undefined>) => {
  const { selectedRows, validationRef } = props;
  const { applicationStore, invoiceStore, configStore } = useStore();
  const { getTranslation, getEnumTranslation, rootStore } = applicationStore;
  const { getExportFileFormats, getDataExports, getBillingParameters } = configStore.configService;
  const { invoiceApiService, getPaymentStatus } = invoiceStore;
  const { exportInvoice, changeExportStatus, bulkExportStatus, formatUBLValidate } = invoiceApiService;

  const downloadService = new DownloadService(rootStore, `/bill/Integration/export/invoice/ublformat/bulk`);

  const { downloadSingleFile, downloadFiles } = downloadService;

  const stateReducer = createStateReducer<State, Partial<State>>();
  const [state, setState] = useReducer(stateReducer, {
    exportOption: 'excel',
    markAsExported: false,
    fileFormats: undefined,
    dataProviders: undefined,
    billingParameters: undefined,
    invoiceIds: selectedRows.map((ivc) => {
      return ivc.__id;
    }),
    validationResponse: undefined
  });

  useEffect(() => {
    Promise.all([getExportFileFormats(), getDataExports(), getBillingParameters()]).then((res) =>
      setState({ fileFormats: res[0], dataProviders: res[1], billingParameters: res[2] })
    );
  }, []);

  useEffect(() => {
    validationRef.current?.setIsError(false);

    if (state.exportOption === 'ubl') {
      setState({ markAsExported: false });
      UBLCalls();
    }
  }, [state.exportOption]);

  const UBLCalls = () => {
    //Use  this to disable the submit button while it's validating
    //validationRef.current?.setIsError(true)
    validationRef.current?.setButtonPositive(getTranslation('invoices_ubl.validating'));

    formatUBLValidate(state.invoiceIds)
      .then((res) => {
        validationRef.current?.setButtonPositive(getTranslation('general.export'));

        if (res.data) {
          if (res.data.generalErrors.length > 0 || res.data.specificErrors.length > 0) {
            validationRef.current?.setIsError(true);
          }
        }

        setState({ validationResponse: res.data });
      })
      .catch((e) => {
        validationRef.current?.setButtonPositive(getTranslation('general.export'));
        validationRef.current?.setIsError(true);

        notify.error({
          content: getTranslation('invoices_ubl.validate_invoice_fail'),
          error: e
        });
      });
  };

  const getInvoiceExportArray = () => {
    const exportArray = [];

    exportArray.push([
      getTranslation('invoices_list.labels.invoiceNum'),
      //getTranslation('contracts.contractor'),
      getTranslation('customer_list.name'),
      getTranslation('customer.customer_id'),
      getTranslation('contracts.contract_id'),
      getTranslation('invoices_list.labels.amount_excl_vat'),
      getTranslation('invoices_list.labels.amount_incl_vat'),
      getTranslation('invoice.open_amount'),
      getTranslation('general.type'),
      getTranslation('invoices_list.labels.invoice_date'),
      getTranslation('invoices_list.labels.due_date'),
      getTranslation('invoices_list.labels.invoice_period'),
      getTranslation('invoices_list.labels.flow'),
      getTranslation('invoices_list.labels.next_step'),
      getTranslation('general.status'),
      getTranslation('invoices_list.labels.payment_status'),
      getTranslation('payments.reference'),
      getTranslation('invoices_list.labels.payment_method'),
      getTranslation('property_groups.name'),
      getTranslation('customer_groups.customer_group')
    ]);

    selectedRows.forEach((r) => {
      const invoice = r.__entity;

      const propertyGroupNames = invoice.propertyGroups?.map((pg) => pg.name) || [];

      exportArray.push([
        invoice.invoiceNum || getTranslation('general.empty'),
        invoice.debtor.displayName,
        invoice.debtor.customerAccountNumber,
        invoice.contractNumber,
        invoice.totalAmountExclVAT,
        invoice.totalAmountInclVAT,
        invoice.remainingInvoiceAmount,
        getEnumTranslation('invoiceType', invoice.type),
        formatDateForExport(invoice.invoiceDate),
        formatDateForExport(invoice.dueDate),
        formatPeriod(invoice.periodStartDateTime, invoice.periodEndDateTime),
        invoice.collectionDetails?.workflowName || '',
        invoice.collectionDetails?.nextStepName || '',
        r.__invoiceStatus,
        getPaymentStatus(invoice).translation,
        invoice.paymentDetails.paymentReference,
        invoice.paymentDetails.paymentMethod,
        //invoice.propertyGroup?.name || '',
        propertyGroupNames.join(','),
        invoice.debtor.customerGroup?.name || ''
      ]);
    });

    return exportArray;
  };

  const getInvoiceLinesExportArray = () => {
    const exportArray = [];

    exportArray.push([
      getTranslation('invoice.invoiceNum'),
      getTranslation('invoice.invoicetype'),
      getTranslation('invoice.invoice_status'),
      getTranslation('general.description'),
      getTranslation('invoice.date'),
      getTranslation('customer.customer_name'),
      getTranslation('customer.customer_id'),
      getTranslation('contracts.contract_id'),
      getTranslation('invoice.lines.lineType'),
      getTranslation('invoice.lines.quantity'),
      getTranslation('meter.unit_of_measure'),
      getTranslation('invoice.lines.unitPrice'),
      getTranslation('invoice.lines.lineAmountExclVAT'),
      getTranslation('invoice.lines.vatPercent'),
      getTranslation('invoice.lines.vat'),
      getTranslation('invoice.lines.lineAmountInclVAT'),
      getTranslation('general.period'),
      getTranslation('property_groups.name'),
      getTranslation('customer_groups.customer_group'),
      getTranslation('location.location_id')
    ]);

    selectedRows.forEach((row) => {
      row.__entity.lines.forEach((line) => {
        const vat_percentage = `${Math.round(line.taxRate * 100).toString()}%`;

        const period = `${line.startDateTime ? formatDate(line.startDateTime) : '...'} - ${
          line.endDateTime ? formatDate(line.endDateTime) : '...'
        }`;

        const propertyGroupNames = row.__entity.propertyGroups?.map((pg) => pg.name) || [];

        exportArray.push([
          row.__entity.invoiceNum,
          getEnumTranslation('invoiceType', row.__entity.type),
          getEnumTranslation('invoiceStatus', row.__entity.status),
          line.description,
          formatDateForExport(row.__entity.invoiceDate),
          row.__entity.debtor.displayName,
          row.__entity.debtor.customerAccountNumber,
          row.__entity.contractNumber,
          getEnumTranslation('invoiceLineType', line.lineType),
          line.quantity,
          getEnumTranslation('unitOfMeasure', line.unitOfMeasure),
          line.unitPrice,
          line.amountExclVAT,
          vat_percentage,
          line.vat,
          line.amountInclVAT,
          period,
          propertyGroupNames.join(','),
          row.__entity.debtor.customerGroup?.name || '',
          line.serviceLocationId
        ]);
      });
    });

    return exportArray;
  };

  const getInvalidInvoicesExportArray = () => {
    const exportArray = [];

    exportArray.push([
      getTranslation('invoices_ubl.export_invoice_number'),
      getTranslation('invoices_ubl.export_error')
    ]);

    //Go through the invalid Invoices, filtered by ids

    state.validationResponse?.specificErrors.forEach((invoice) => {
      let ogMessage = invoice.errorMessage.message;
      let replacedMessage = '';

      if (invoice.errorMessage.messageValues && invoice.errorMessage.messageValues.length > 0) {
        for (let j = 0; j < invoice.errorMessage.messageValues?.length; j++) {
          replacedMessage = ogMessage.replace(`\${${j}}`, invoice.errorMessage.messageValues[j].value);
          ogMessage = replacedMessage;
        }

        exportArray.push([invoice.invoiceNumber, replacedMessage]);
      } else {
        exportArray.push([invoice.invoiceNumber, invoice.errorMessage.message]);
      }
    });

    return exportArray;
  };

  async function batchMarkExportedInvoices(arr: string[]) {
    let init = 0;
    for (let i = 0; i < arr.length / 200; i++) {
      await bulkExportStatus(arr.slice(init, (init += 200)), exportStatus.exported);
    }
  }

  useImperativeHandle(ref, () => ({
    async onClick() {
      switch (state.exportOption) {
        case 'invoiceLines':
          exportArrayToExcel(getTranslation('actions.invoice.invoice_lines_for_period'), getInvoiceLinesExportArray);
          break;
        case 'file':
          try {
            await exportInvoice(selectedRows);
            notify.success({
              content: getTranslation('actions.invoice.create_export_success')
            });
          } catch (error) {
            notify.error({
              content: getTranslation('actions.invoice.create_export_failed'),
              error
            });
          }
          break;
        case 'ubl':
          if (state.invoiceIds.length === 1) {
            try {
              downloadSingleFile(`/bill/Integration/export/invoice/ublformat/single`, {
                invoiceId: state.invoiceIds[0],
                markedAsExported: state.markAsExported
              });

              notify.success({
                content: getTranslation('invoice.exported')
              });
            } catch (e) {
              notify.error({
                content: getTranslation('invoice.exported_fail'),
                error: e
              });
            }
          } else if (state.invoiceIds.length > 1) {
            try {
              downloadFiles(state.invoiceIds, 'invoiceIds', {
                markedAsExported: state.markAsExported
              });

              notify.success({
                content: getTranslation('invoice.exported')
              });
            } catch (e) {
              notify.error({
                content: getTranslation('invoice.exported_fail'),
                error: e
              });
            }
          }

          break;

        // Excel
        default:
          exportArrayToExcel(getTranslation('invoice.invoices'), getInvoiceExportArray);
      }

      if (state.markAsExported) {
        /**
         * @description use bulk API endpoint if user select more than one invoice.
         */

        const ids = selectedRows.map((r) => r.__id);
        const isSingleExport = ids.length === 1 && !!ids[0];
        const isBulkExport = ids.length > 1 && ids.some((id) => !!id);

        try {
          if (isSingleExport) {
            await changeExportStatus(ids[0], exportStatus.exported);
          } else if (isBulkExport) {
            await batchMarkExportedInvoices(ids);
          }
        } catch ({ data: { errors } }) {
          notify.error({
            content: (
              <>
                <Paragraph>
                  {isSingleExport && getTranslation('invoice.mark_as_not_exported_fail')}
                  {isBulkExport && getTranslation('invoice.mark_as_not_exported_fail_multi')}
                </Paragraph>
                <NotificationError errors={errors} />
              </>
            )
          });
        }
      }
    }
  }));

  if (!state.fileFormats || !state.dataProviders) return <Spinner centered />;

  const applicableFormatsString = state.fileFormats.reduce((acc: string, f) => {
    if (f.settings.type === exportSettingsCategoryType.invoice) {
      acc += `<li>${f.name}</li>`;
    }

    return acc;
  }, '');

  const exportAsFileDisabled = state.dataProviders.length === 0 || applicableFormatsString.length === 0;

  ///

  return (
    <>
      <Paragraph>{getTranslation('invoice.export_dialog_info')}</Paragraph>

      <div className={css['radio-options']}>
        <RadioInput
          onChange={(option) => setState({ exportOption: option as ExportOption })}
          value="excel"
          name="export-option"
          checked={state.exportOption === 'excel'}
          description={getTranslation('invoice.export_option_excel_descr')}
        >
          <Paragraph textAlign="left">{getTranslation('export_excel.export_invoices')}</Paragraph>
        </RadioInput>

        <RadioInput
          onChange={(option) => setState({ exportOption: option as ExportOption })}
          value="invoiceLines"
          name="export-option"
          checked={state.exportOption === 'invoiceLines'}
          description={getTranslation('invoice.export_option_excel_descr')}
        >
          <Paragraph textAlign="left">{getTranslation('invoice.export_option_invoice_lines')}</Paragraph>
        </RadioInput>

        <Disabled disabled={exportAsFileDisabled}>
          <RadioInput
            onChange={(option) => setState({ exportOption: option as ExportOption, markAsExported: true })}
            value="file"
            name="export-option"
            checked={state.exportOption === 'file'}
            description={
              exportAsFileDisabled
                ? getTranslation('invoice.export_option_file_no_providers_descr')
                : getTranslation('invoice.export_option_file_descr', { applicableFormatsString })
            }
          >
            <Paragraph textAlign="left">{getTranslation('invoice.export_option_file')}</Paragraph>
          </RadioInput>
        </Disabled>

        {state.billingParameters?.enableUBL && (
          <RadioInput
            onChange={(option) => setState({ exportOption: option as ExportOption, markAsExported: false })}
            value="ubl"
            name="export-option"
            checked={state.exportOption === 'ubl'}
            description={getTranslation('invoices_ubl.label_get_xml')}
          >
            <Paragraph textAlign="left">{getTranslation('invoices_ubl.label_ubl_compliant')}</Paragraph>
          </RadioInput>
        )}
      </div>

      {/**
       * @description general issues
       */}
      {state.exportOption === 'ubl' && state.validationResponse && (
        <FlexElements flexDirection="column" className={css['ubl-export-warnings']}>
          {state.validationResponse?.generalErrors.length > 0 && (
            <InfoBanner
              info={
                <ul>
                  {state.validationResponse.generalErrors.map((e) => {
                    let ogMessage = e.errorMessage.message;
                    let replacedMessage = '';
                    let replacedMessageWithBold = '';

                    if (e.errorMessage.messageValues) {
                      for (let i = 0; i < e.errorMessage.messageValues?.length; i++) {
                        //eslint-disable-next-line
                        replacedMessageWithBold = ogMessage.replace(`\${${i}}`, '<b>${' + `${i}` + '}</b>');
                        replacedMessage = replacedMessageWithBold.replace(
                          `\${${i}}`,
                          e.errorMessage.messageValues[i].value
                        );
                        ogMessage = replacedMessage;
                      }

                      return (
                        <li key={e.errorMessage.key}>
                          <Interweave
                            content={replacedMessage.substring(0, 1).toUpperCase() + replacedMessage.substring(1)}
                          ></Interweave>
                        </li>
                      );
                    } else {
                      return <li key={e.errorMessage.key}>{e.errorMessage.key}</li>;
                    }
                  })}
                </ul>
              }
              color={'orange'}
            />
          )}

          {/**
           * @description invoice issues
           */}

          {state.validationResponse?.specificErrors.length > 0 && (
            <InfoBanner
              info={
                <FlexElements flexDirection="column" justifyContent="center">
                  <Interweave
                    className={css['invoice-issues-desc']}
                    content={getTranslation('invoices_ubl.invalid_invoices', {
                      amount: state.validationResponse.uniqueInvoicesWithErrors
                    })}
                  />
                  <Button
                    type="text"
                    icon="download"
                    id="download-invalid-invoice"
                    onClick={() => {
                      exportArrayToExcel('UBL-errors', getInvalidInvoicesExportArray);
                    }}
                  >
                    {getTranslation('invoices_ubl.download_invalid_invoice')}
                  </Button>
                </FlexElements>
              }
              color={'orange'}
            />
          )}
        </FlexElements>
      )}

      <HorizontalDivider />

      <CheckBox
        id="mark-all-as-exported"
        title={getTranslation('invoice.mark_all_as_exported', { number: selectedRows.length })}
        checked={state.markAsExported}
        onChange={() => setState({ markAsExported: !state.markAsExported })}
      />
    </>
  );
});

export default observer(ExportDialog);
