import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { contractBillingMethod, meteringType, meterStatus } from '@zf/api-types/enums';
import { ContractType } from '@zf/api-types/master-data/contract';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';

import { useAppContext } from '../../../../../app-context';
import { Spinner } from '../../../../../design-system/Foundation';
import { useStore } from '../../../../../hooks/useStore';
import { getCurrentMeterStatus } from '../../../../../utils/meter';
import BillingDetailsCard from './cards/BillingDetailsCard';
import PrepaymentDeviceCard from './cards/PrepaymentDeviceCard';

type Props = {
  contract: ContractType;
  setActiveTab: (tabId: string) => void;
};

const BillingDetailsOrPrepayment = (props: Props) => {
  const { contract } = props;
  const { i18n } = useAppContext();
  const { contractStore, meterStore } = useStore();
  const { prepaymentDevice, setPrepaymentDevice } = contractStore;
  const { getMeters } = meterStore.deviceService;

  useEffect(() => {
    // Replace this logic with something more robust when going full prepayment
    const sluuids = contract.serviceLocations.map((l) => l.id);

    getMeters({ sluuids }).then((meters) => {
      // Null means API has been called
      setPrepaymentDevice(
        meters.find(
          (m) =>
            getCurrentMeterStatus(m, i18n)?.meterStatus === meterStatus.installed &&
            m.channels.some((c) => c.meteringType === meteringType.balance)
        ) || null
      );
    });
  }, [contract.id]);

  if (typeof prepaymentDevice === 'undefined') {
    return (
      <Card>
        <CardHeader>{''}</CardHeader>
        <CardBody>
          <Spinner centered />
        </CardBody>
      </Card>
    );
  }

  return contract.billingDetails.billingMethod === contractBillingMethod.credit ? (
    <BillingDetailsCard setActiveTab={props.setActiveTab} />
  ) : (
    <PrepaymentDeviceCard prepaymentDevice={prepaymentDevice} />
  );
};

export default observer(BillingDetailsOrPrepayment);
