import React from 'react';

import { BillingAnalyticsType } from '@zf/api-types/analytics/billing';
import { MasterDataAnalyticsType } from '@zf/api-types/analytics/masterdata';
import { MeteringAnalyticsType } from '@zf/api-types/analytics/metering';
import { PaymentAnalyticsType } from '@zf/api-types/analytics/payments';
import { Card, CardBody, CardHeader } from '@zf/stella-react/src/atoms/Card';
import { Label } from '@zf/stella-react/src/atoms/Label';

import { useAppContext } from '../../../../app-context';
import CardHeaderIcon from '../../../../components/Icon/CardHeaderIcon';
import IconParagraph from '../../../../components/Icon/IconParagraph';
import { BaseLink } from '../../../../design-system/Components';
import { insightsSubjectType } from '../../Dashboard';
import css from './todo-card.module.scss';
import TodoPayments from './TodoPayments';

type Props = {
  subject: insightsSubjectType;
  dataObject: MeteringAnalyticsType | BillingAnalyticsType | PaymentAnalyticsType | MasterDataAnalyticsType;
  setActiveTab?: (tabId: string) => void;
};

export default function TodoCard(props: Props) {
  const { subject, dataObject, setActiveTab = () => {} } = props;
  const { i18n, tenantReducer } = useAppContext();
  const { rootUrl } = tenantReducer.state;

  let content: JSX.Element | null = null;

  const generateIconParagraph = React.useCallback((text: string) => {
    return (
      <IconParagraph className={css['arrow-paragraph']} iconType="arrow-right" iconPosition="right">
        {text}
      </IconParagraph>
    );
  }, []);

  switch (subject) {
    case 'metering': {
      const data = dataObject as MeteringAnalyticsType;
      content = (
        <>
          <BaseLink
            id="todo-not-installed"
            className={css['todo-item']}
            url={`${rootUrl}/devices/meters/?quickFilter=inactive`}
          >
            <Label>{i18n.getTranslation('dashboard.general_tab.not_installed')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.meters', {
                amount: data.amountOfMetersNotInstalled
              })
            )}
          </BaseLink>
        </>
      );
      break;
    }
    case 'billing': {
      const data = dataObject as BillingAnalyticsType;
      content = (
        <>
          <BaseLink
            id="todo-to-approve"
            className={css['todo-item']}
            url={`${rootUrl}/invoices/?quickFilter=toapprove`}
          >
            <Label>{i18n.getTranslation('dashboard.general_tab.to_approve')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.invoices', { amount: data.invoicesToApprove })
            )}
          </BaseLink>

          <BaseLink id="todo-to-send" className={css['todo-item']} url={`${rootUrl}/invoices/?quickFilter=tosend`}>
            <Label>{i18n.getTranslation('dashboard.general_tab.to_send')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.invoices', { amount: data.invoicesToSend })
            )}
          </BaseLink>

          <BaseLink
            id="todo-manually-corrected"
            className={css['todo-item']}
            url=""
            onNavigate={() => setActiveTab('billing_overview')}
          >
            <Label>{i18n.getTranslation('dashboard.general_tab.manually_corrected')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.invoices', {
                amount: data.periodsWaitingAfterManuallyCorrected
              })
            )}
          </BaseLink>

          <BaseLink id="todo-blocked" className={css['todo-item']} url={`${rootUrl}/contracts?quickFilter=blocked`}>
            <Label>{i18n.getTranslation('dashboard.general_tab.contracts_blocked')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.contracts', { amount: data.contractsBlocked })
            )}
          </BaseLink>
        </>
      );
      break;
    }
    case 'collections': {
      const data = dataObject as PaymentAnalyticsType;
      content = <TodoPayments data={data} generateIconParagraph={generateIconParagraph} />;
      break;
    }
    case 'master-data': {
      const data = dataObject as MasterDataAnalyticsType;
      content = (
        <>
          <BaseLink id="todo-draft" className={css['todo-item']} url={`${rootUrl}/contracts/?quickFilter=draft`}>
            <Label>{i18n.getTranslation('dashboard.general_tab.draft_contracts')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.contracts', { amount: data.draftContracts })
            )}
          </BaseLink>

          <BaseLink
            id="todo-unsupplied"
            className={css['todo-item']}
            url={`${rootUrl}/locations/?quickFilter=unsupplied`}
          >
            <Label>{i18n.getTranslation('dashboard.general_tab.unsupplied_locations')}</Label>
            {generateIconParagraph(
              i18n.getTranslation('dashboard.general_tab.locations', { amount: data.unsuppliedLocations })
            )}
          </BaseLink>

          <BaseLink id="todo-new-tasks" className={css['todo-item']} url={`${rootUrl}/tasks?quickFilter=new`}>
            <Label>{i18n.getTranslation('dashboard.general_tab.new_tasks')}</Label>
            {generateIconParagraph(i18n.getTranslation('dashboard.general_tab.newTasks', { amount: data.newTasks }))}
          </BaseLink>
        </>
      );
      break;
    }
  }

  return (
    <Card id={`${subject}-todo-card`}>
      <CardHeader extraLeft={<CardHeaderIcon type="todo" />}>
        {i18n.getTranslation('dashboard.general_tab.todo')}
      </CardHeader>
      <CardBody className={css['todos']}>{content}</CardBody>
    </Card>
  );
}
